@import 'styles/variables';
@import 'styles/mixins';

.Footer {
  position: relative;
  padding-top: 36px;
  background-color: $charcoal-grey;
  padding-bottom: 50px;
  @include sm {
    padding-top: 32px;
    padding-bottom: 40px;
  }
  @include xs {
    padding-top: 24px;
  }
}

.Footer-Block {
  position: relative;
  padding-left: 246px;
  @include sm {
    padding-left: 172px;
  }
  @include xs {
    padding-left: 142px;
  }
}

.Footer-Image {
  position: absolute;
  bottom: -66px;
  left: 0;
  @include sm {
    bottom: -63px;
  }
  @include xs {
    left: -24px;
    bottom: -174px;
  }
}

.Footer-Attention {
  font-size: 24px;
  color: white;
  margin-bottom: 20px;
  line-height: 1.3;
  & > b {
    font-weight: bold;
  }
  @include sm {
    font-size: 16px;
    margin-bottom: 16px;
  }
  @include xs {
    margin-bottom: 8px;
  }
}

.Footer-SocialList {
  display: flex;
  @include xs {
    margin-bottom: 16px;
  }
}

.Footer-SocialItem {
  &:not(:last-child) {
    margin-right: 14px;
    @include sm {
      margin-right: 10px;
    }
  }
}

.Footer-SocialLink{
  display: flex;
  align-items: center;
  justify-content: center;
  color: $light-peach;
  transition: color $shortest $ease-in-out;
  @include hover {
    color: white;
  }
  @include sm {
   & > svg {
     width: 24px;
     height: 24px;
   }
  }
}

.Footer-Title {
  @include sm {
    margin-bottom: 4px;
  }
}

.Footer-Blog {
  @include xs {
    padding-left: 172px;
  }
  @include xs {
    padding-left: 142px;
  }
}

.Footer-BlogLink {
  display: inline-block;
  opacity: 0.8;
  font-size: 14px;
  padding-top: 2px;
  padding-bottom: 2px;
  color: $light-blue-grey;
  transition: color $shortest $ease-in-out;
  @include hover {
    color: white;
  }
  @include sm {
    font-size: 12px;
    padding-top: 0;
    padding-bottom: 0;
  }
  @include xs {
    font-size: 11px;
  }
}

.Footer-Company {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20px;
  font-size: 12px;
  line-height: 1.67;
  color: rgba(white, 0.5);
  @include sm {
    font-size: 10px;
    bottom: 10px;
  }
  @include xs {
    font-size: 8px;
    width: 100%;
    padding-left: 166px;
  }
}

.Footer-CompanySpacer {
  margin: 0 6px;
}

.Footer-DeveloperLink {
  display: inline-flex;
  align-items: center;
  font-size: 12px;
  color: rgba(white, 0.5);
  @include sm {
    font-size: 10px;
  }
  @include xs {
    font-size: 8px;
  }
  @include hover {
    text-decoration: underline;
  }
}

.Footer-DeveloperLogo {
  width: 9px;
  height: 9px;
  margin-right: 4px;
  @include sm {
    width: 7px;
    height: 7px;
  }
  @include xs {
    width: 6px;
    height: 6px;
  }
}