@import 'styles/variables';
@import 'styles/mixins';

.ApplicationEntry {
  margin-bottom: 132px;
  @include sm {
    margin-bottom: 84px;
  }
  @include xs {
    margin-bottom: 40px;
  }
}

.ApplicationEntry-Attention {
  line-height: 1.14;
  margin-bottom: 16px;
}

.ApplicationEntry-Text {
  font-size: 20px;
  line-height: 1.5;
  color: rgba($charcoal-grey, 0.6);
  max-width: 512px;
  @include sm {
    font-size: 14px;
    line-height: 1.5;
  }
}
