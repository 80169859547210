@import 'styles/variables';
@import 'styles/mixins';

.SubscriptionButtons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  @include xs {
    align-items: center;
    flex-direction: column;
  }
}

.SubscriptionButtons-Block {
  position: relative;
  &:not(:last-child) {
    margin-right: 16px;
    @include sm {
      margin-right: 10px;
    }
    @include xs {
      margin-right: 0;
      margin-bottom: 14px;
    }
  }
}

.SubscriptionButtons-Text {
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  @include sm {
    margin-top: 8px;
    font-size: 10px;
  }
}
