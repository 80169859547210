@mixin image2x($image) {
  @media (min-resolution: 1.5dppx), (-webkit-min-device-pixel-ratio: 1.5) {
    background-image: url($image);
  }
}

@mixin hover {
  @media (hover: hover) {
    &:hover,
    &:focus {
      @content;
    }
  }
}

@mixin xs {
  @media (max-width: $xs) {
    @content;
  }
}

@mixin sm {
  @media (max-width: $sm) {
    @content;
  }
}

@mixin md {
  @media (max-width: $md) {
    @content;
  }
}
