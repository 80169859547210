@import 'styles/variables';
@import 'styles/mixins';

.Benefits {
  padding-top: 24px;
  padding-bottom: 200px;
  text-align: center;
  @include sm {
    padding-bottom: 120px;
  }
  @include xs {
    padding-bottom: 80px;
  }
}

.Benefits-Title {
  margin-bottom: 36px;
  @include sm {
    line-height: 1.27;
  }
}

.Benefits-List {
  display: flex;
  margin-bottom: 64px;
  flex-wrap: wrap;
  @include xs {
    margin-bottom: 24px;
  }
}

.Benefits-Item {
  width: 25%;
  padding-left: 32px;
  padding-right: 32px;
  @include md {
    padding-left: 24px;
    padding-right: 24px;
  }
  @include sm {
    padding-left: 16px;
    padding-right: 16px;
  }
  @include xs {
    padding-left: 13px;
    padding-right: 13px;
    width: 50%;
    margin-bottom: 24px;
  }
}

.Benefits-ItemIcon {
  margin-bottom: 12px;
  @include sm {
    width: 42px;
    height: 42px;
    margin-bottom: 8px;
  }
}

.Benefits-ItemTitle {
  font-size: 24px;
  line-height: 1.17;
  color: $charcoal-grey;
  margin-bottom: 16px;
  @include sm {
    font-size: 16px;
    line-height: 1.19;
    margin-bottom: 8px;
  }
}

.Benefits-ItemText {
  font-size: 15px;
  line-height: 1.4;
  color: $brown-grey;
  @include sm {
    font-size: 11px;
    line-height: 1.36;
  }
}
