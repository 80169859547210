@import 'styles/variables';
@import 'styles/mixins';

.Workout {
  position: relative;
  background: $background;
}

.Workout-Container {
  text-align: center;
  padding-top: 96px;
  padding-bottom: 28px;
  @include sm {
    padding-top: 60px;
  }
}

.Workout-Attention {
  margin-bottom: 28px;
  @include xs {
    line-height: 1.07;
  }
}

.Workout-Types {
  display: flex;
  @include xs {
    flex-direction: column;
  }
}

.Workout-Type {
  width: 50%;
  position: relative;
  background: center / contain no-repeat;
  &_first {
    margin-right: 26px;
    background-image: url('../assets/images/blocks/at-home.png');
    @include image2x('../assets/images/blocks/at-home@2x.png');
    @include md {
      margin-right: 10px;
    }
    @include sm {
      background-image: url('../assets/images/blocks/at-home_mobile.png');
      @include image2x('../assets/images/blocks/at-home_mobile@2x.png');
    }
    @include xs {
      margin-right: 0;
    }
  }
  &_second {
    margin-left: 26px;
    background-image: url('../assets/images/blocks/gym.png');
    @include image2x('../assets/images/blocks/gym@2x.png');
    @include md {
      margin-left: 10px;
    }
    @include sm {
      background-image: url('../assets/images/blocks/gym_mobile.png');
      @include image2x('../assets/images/blocks/gym_mobile@2x.png');
    }
    @include xs {
      margin-left: 0;
      margin-top: -6%;
    }
  }
  @include xs {
    width: 100%;
  }
  &::after {
    content: '';
    display: block;
    position: relative;
    padding-bottom: 68.6%;
    @include sm {
      padding-bottom: 75.5%;
    }
  }
}

.Workout-TypeContent {
  display: flex;
  flex-direction: column;
  text-align: left;
  position: absolute;
  justify-content: center;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding-left: 11%;
  padding-right: 11%;
  .Workout-Type_second > & {
    text-align: right;
    padding-top: 50px;
    @include sm {
      padding-top: 33px;
    }
  }
  @include sm {
    padding-left: 9%;
    padding-right: 9%;
  }
}

.Workout-TypeTitle {
  font-size: 54px;
  font-weight: 900;
  line-height: 0.94;
  color: white;
  text-transform: uppercase;
  margin-bottom: 6px;
  @include sm {
    font-size: 36px;
  }
}

.Workout-TypeSubtitle {
  font-size: 13px;
  font-weight: bold;
  line-height: 1.38;
  letter-spacing: 1px;
  color: white;
  text-transform: uppercase;
  margin-bottom: 24px;
  @include sm {
    font-size: 10px;
    line-height: 1.2;
    letter-spacing: 0.77px;
    margin-bottom: 16px;
  }
}

.Workout-TypeText {
  max-width: 120px;
  .Workout-Type_second & {
    max-width: 138px;
    align-self: flex-end;
    @include sm {
      max-width: 96px;
    }
  }
  @include sm {
    font-size: 11px;
    line-height: 1.27;
    max-width: 83px;
  }
}