$dark: #232c38;
$charcoal-grey: #3b4554;
$brown-grey: #828282;
$light-blue-grey: #d4d6d9;
$background: #fbfbfb;
$light-grey-two: #d2eccb;
$pale-aqua: #beece8;
$light-teal: #96e3dd;
$light-peach: #ffbdb5;
$blush: #f39388;
$sandy: #f2df7e;

$font-family: 'Boston', sans-serif;
$min-width: 960px;
$sidebar-width: 240px;
$header-height: 64px;

$xs: 599px;
$sm: 959px;
$md: 1279px;

$shortest: 150ms;
$shorter: 200ms;
$short: 250ms;
$standard: 300ms;
$medium: 500ms;
$long: 800ms;
$longer: 1000ms;
$longest: 2000ms;
$ease-in-out: cubic-bezier(0.4, 0, 0.2, 1);
$ease-out: cubic-bezier(0, 0, 0.2, 1);
