@import 'styles/variables';
@import 'styles/mixins';

.About {
  padding-top: 145px;
  padding-bottom: 340px;
  background: url('../assets/images/blocks/melissa-about.png') 40px 40% / 891px 666px no-repeat;
  @include image2x('../assets/images/blocks/melissa-about@2x.png');
  @include md {
    background-size: 712px 532px;
    background-position: -40px 35%;
    padding-top: 100px;
    padding-bottom: 200px;
  }
  @include sm {
    background-size: 519px 467px;
    background-position: -20px center;
    background-image: url('../assets/images/blocks/melissa-about_mobile.png');
    @include image2x('../assets/images/blocks/melissa-about_mobile@2x.png');
  }
  @include xs {
    padding-top: 80px;
    padding-bottom: 56px;
    background-position: left 60px;
  }
}

.About-Block {
  @include xs {
    height: 467px;
  }
}

.About-Text {
  font-size: 20px;
  line-height: 1.5;
  color: $brown-grey;
  margin-bottom: 42px;
  @include sm {
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 24px;
  }
}

.About-List {
  display: flex;
  flex-wrap: wrap;
}

.About-Item {
  &:not(:last-child) {
    margin-right: 20px;
    @include sm {
      margin-right: 16px;
    }
  }
}

.About-Link {
  color: $blush;
  display: inline-flex;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.67;
  transition: color $shortest $ease-in-out;
  @include hover {
    color: $charcoal-grey;
  }
  @include sm {
    font-size: 12px;
    line-height: 1.75;
    & > svg {
      width: 24px;
    }
  }
}

.About-LinkName {
  margin-left: 6px;
}

.About-Word {
  position: relative;
  display: inline-block;
  color: $dark;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 109px;
    height: 100%;
    background-color: rgba($sandy, 0.6);
    @include sm {
      width: 76px;
    }
  }
  & > span {
    position: relative;
  }
}