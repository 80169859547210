@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInLeft {
  from {
    transform: translate3d(-5%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translate3d(5%, 0, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInBottomSmall {
  from {
    transform: translate3d(0, 5%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInBottom {
  from {
    transform: translate3d(0, 25%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInTopSmall {
  from {
    transform: translate3d(0, -5%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes fadeInTop {
  from {
    transform: translate3d(0, -25%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@keyframes scaleOut {
  from {
    transform: scale(1.2);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scaleIn {
  from {
    transform: scale(0.85);
    opacity: 1;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes translateArrowDownUp {
  from {
    transform: translate3d(-50%, 0, 0);
  }
  35% {
    transform: translate3d(-50%, 0, 0);
  }
  45% {
    transform: translate3d(-50%, 20%, 0);
  }
  65% {
    transform: translate3d(-50%, 0, 0);
  }
  75% {
    transform: translate3d(-50%, 15%, 0);
  }
  to {
    transform: translate3d(-50%, 0, 0);
  }
}

.FadeIn {
  animation: fadeIn $long $ease-in-out both;
}
