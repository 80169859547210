.VisuallyHidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  bottom: 0;
  padding: 0;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
}

.Container {
  padding-left: 40px;
  padding-right: 40px;
  max-width: 1424px;
  margin: auto;
  @include sm {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.Title {
  font-size: 16px;
  font-weight: 900;
  line-height: 1.25;
  letter-spacing: 1px;
  color: $light-blue-grey;
  text-transform: uppercase;
  margin-bottom: 12px;
  &_white {
    color: white;
  }
  @include sm {
    font-size: 11px;
    line-height: 0.95;
    letter-spacing: 0.69px;
  }
}

.Attention {
  font-size: 42px;
  line-height: 1.52;
  margin-bottom: 8px;
  & > b {
    font-weight: normal;
  }
  @include sm {
    font-size: 30px;
    line-height: 1.49;
  }
}

.LightPeach {
  color: $light-peach;
  text-decoration: line-through
}
