@font-face {
  font-family: 'Boston';
  src: url('../assets/fonts/boston-light.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../assets/fonts/boston-regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../assets/fonts/boston-bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Boston';
  src: url('../assets/fonts/boston-black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
