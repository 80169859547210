@import 'styles/variables';

.Screens {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Screens-Image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  &_first {
    .Screens_first > & {
      z-index: 1;
      transform: translate3d(0, 0, 0);
      transition: transform $long $ease-in-out;
    }
    .Screens_second > & {
      z-index: 0;
      transform: translate3d(100%, 0, 0);
      transition: transform $long step-end;
    }
  }
  &_second {
    .Screens_first > & {
      z-index: 0;
      transform: translate3d(100%, 0, 0);
      transition: transform $long step-end;
    }
    .Screens_second > & {
      z-index: 1;
      transform: translate3d(0, 0, 0);
      transition: transform $long $ease-in-out;
    }
  }
}
