body {
  min-width: 320px;
}

#root {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}

button {
  &:focus {
    outline: none;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}