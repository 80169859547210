@import 'styles/variables';
@import 'styles/mixins';

.Application {
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $sandy;
    transform: skewY(1deg);
  }
  &::after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: calc(53% + 1.5vw);
    bottom: -1.5vw;
    left: 0;
    background-color: $charcoal-grey;
    transform: skewY(1.5deg);
    @include sm {
      height: calc(50% + 1.5vw);
    }
  }
}

.Application-Container {
  position: relative;
  z-index: 1;
  padding-top: 66px;
  padding-bottom: 56px;
  @include sm {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}

.Application-Actions {
  margin-bottom: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include sm {
    margin-bottom: 16px;
  }
}

.Application-Pagination {
  display: flex;
  margin-left: -12px;
}

.Application-PaginationItem {
  &:not(:last-child) {
    margin-right: 8px;
    @include xs {
      margin-right: 0;
    }
  }
}

.Application-Feature {
  color: white;
  height: 248px;
  @include sm {
    height: 167px;
  }
}

.Application-Title {
  font-size: 24px;
  line-height: 2.17;
  letter-spacing: 2px;
  text-transform: uppercase;
  margin-bottom: 16px;
  @include sm {
    font-size: 17px;
    line-height: 1.5;
    letter-spacing: 1.42px;
  }
}

.Application-Text {
  font-size: 20px;
  line-height: 1.5;
  max-width: 512px;
  @include sm {
    font-size: 14px;
  }
}

.Application-Content {
  position: relative;
  display: flex;
  justify-content: center;
}

.Application-Phone {
  position: relative;
  top: -132px;
  width: 368px;
  height: 738px;
  background-color: white;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 56px;
  padding: 12px 18px;
  &::before {
    content: '';
    display: block;
    position: absolute;
    z-index: 2;
    left: -142px;
    top: -60px;
    width: 542px;
    height: 878px;
    background: url('../assets/images/figures/phone.png') center/ contain no-repeat;
    @include image2x('../assets/images/figures/phone@2x.png');
    @include sm {
      width: 363px;
      height: 587px;
      left: -97px;
      top: -40px;
      background-image: url('../assets/images/figures/phone_mobile.png');
      @include image2x('../assets/images/figures/phone_mobile@2x.png');
    }
  }
  @include sm {
    top: -64px;
    width: 242px;
    height: 492px;
    border-radius: 40px;
    padding: 6px 10px;
  }
  @include xs {
    top: 0;
    margin-bottom: 40px;
  }
}

.Application-PhoneImage {
  width: 100%;
}

.Application-Parallax {
  position: relative;
  height: 100%;
  overflow: hidden;
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 64px;
    background: url('../assets/images/blocks/panel.png') center / contain no-repeat;
    @include image2x('../assets/images/blocks/panel@2x.png');
    @include sm {
      height: 44px;
    }
  }
}

.Application-ImageScroll {
  width: 100%;
}