@import 'styles/variables';
@import 'styles/mixins';

.Header {
  position: relative;
  background-image: linear-gradient(245deg, $light-grey-two, $pale-aqua 63%, $light-teal);
  height: calc(100vh - 38px);
  min-height: 650px;
  @include sm {
    min-height: 462px;
    max-height: 520px;
  }
  @include xs {
    min-height: 700px;
    max-height: 774px;
  }
}

.Header-Container {
  position: relative;
  padding-top: 98px;
  padding-bottom: 85px;
  height: 100%;
  @include sm {
    padding-top: 68px;
    padding-bottom: 54px;
  }
  @include xs {
    padding-top: 40px;
  }
}

.Header-Blotch {
  position: absolute;
  right: 96px;
  top: 20px;
  height: 100%;
  & > svg {
    height: 100%;
  }
  @include sm {
    right: 12%;
  }
}

.Header-Melissa {
  position: absolute;
  right: 120px;
  top: 0;
  height: 100%;
  & > img {
    width: auto;
    height: 100%;
  }
  @include sm {
    right: 12%;
  }
  @include xs {
    right: 0;
  }
}

.Header-Content {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Header-Block {
  height: 100%;
  display: flex;
  align-items: center;
}

.Header-Panel {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 82px;
  @include sm {
    margin-bottom: 62px;
  }
}

.Header-Logo {
  flex-shrink: 0;
  width: 217px;
  height: 54px;
  @include sm {
    width: 148px;
    height: 37px;
  }
}

.Header-Links {
  position: relative;
  top: -40px;
  @include sm {
    top: -28px;
  }
}

.Header-Attention {
  font-size: 120px;
  font-weight: 900;
  line-height: 0.92;
  color: white;
  text-transform: uppercase;
  margin-bottom: 16px;
  & span {
    display: inline-block;
  }
  @include sm {
    font-size: 84px;
    margin-bottom: 8px;
  }
}

.Header-AttentionPart {
  color: $light-peach;
}

.Header-Text {
  color: rgba($dark, 0.8);
  font-size: 24px;
  line-height: 1.67;
  margin-bottom: 20px;
  @include sm {
    margin-bottom: 14px;
    font-size: 16px;
    line-height: 1.75;
  }
  @include xs {
    max-width: 181px;
    line-height: 1.25;
    margin-bottom: 24px;
  }
}

.Header-Scroll {
  position: absolute;
  left: 50%;
  bottom: -30px;
  width: 60px;
  height: 60px;
  background-color: $background;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  animation: translateArrowDownUp 2500ms $ease-in-out both infinite;
  @include sm {
    width: 42px;
    height: 42px;
    bottom: -21px;
    & > svg {
      width: 10px;
      height: 18px;
    }
  }
}

.Header-Subscription {
  display: flex;
  @include xs {
    flex-direction: column;
    align-items: flex-start;
  }
}
